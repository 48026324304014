import React from "react";
import styled from "styled-components";
import { ReactComponent as YoutubeIcon } from "../assets/youtube.svg";
import { ReactComponent as TwitterIcon } from "../assets/twitter.svg";
import { ReactComponent as NoteIcon } from "../assets/note.svg";

const YoutubeIconWrap = styled(YoutubeIcon)`
  fill: #e30000;
  width: 30px;
  height: auto;
`;

const TwitterIconWrap = styled(TwitterIcon)`
  width: 26px;
  height: auto;
`;

const NoteIconWrap = styled(NoteIcon)`
  width: 40px;
  height: auto;
`;

const Footer = styled.footer``;

const SnsIcons = styled.ul`
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 40px auto 12px;
`;

const SnsIconWrap = styled.li`
  display: inline-block;
`;

const Copyright = styled.p`
  text-align: center;
  margin: 0;
  font-size: 1.2rem;
  height: 60px;
`;

const BlogLinkWrap = styled.p`
  display: flex;
  justify-content: center;
  margin: 0 0 20px;
`;

const BlogLink = styled.a`
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
  border: 1px solid #ccc;
  padding: 4px 20px 4px 46px;
  border-radius: 30px;
  width: 200px;
  &:hover {
    border: 1px solid #999;
  }
`;

export default () => {
  return (
    <Footer>
      <SnsIcons>
        <SnsIconWrap>
          <a href="https://www.youtube.com/c/shigekitakeguchi" target="_blank" rel="noopener noreferrer">
            <YoutubeIconWrap />
          </a>
        </SnsIconWrap>
        <SnsIconWrap>
          <a href="https://twitter.com/shigektakeguchi" target="_blank" rel="noopener noreferrer">
            <TwitterIconWrap />
          </a>
        </SnsIconWrap>
        <SnsIconWrap>
          <a href="https://www.instagram.com/shigeki_takeguchi" target="_blank" rel="noopener noreferrer">
            <img src="/images/instagram.png" width="36" height="36" alt="Instagram" />
          </a>
        </SnsIconWrap>
      </SnsIcons>
      <BlogLinkWrap>
        <BlogLink href="https://note.com/shigekitakeguchi/m/mb3d945011318" target="_blank">
          <NoteIconWrap />
          ブログ
        </BlogLink>
      </BlogLinkWrap>
      <Copyright>© 2020 video.shigekitakeguchi.com ワンカット散歩動画</Copyright>
    </Footer>
  );
};
