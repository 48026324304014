import React from "react";
import { Layout } from "../components/Layout";
import styled from "styled-components";
import { useState, useEffect } from "react";
import mediaQuery from "styled-media-query";
const mediaMobile = mediaQuery.lessThan("medium");

interface ContentProps {
  contentHeight: number;
}

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  ${mediaMobile`
    left: -100%;
  `}
`;

const MainHeadings = styled.h1`
  font-size: 4rem;
  color: #000;
`;

const VideoWrap = styled.div<ContentProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: ${(props) => props.contentHeight}px;
  overflow-x: hidden;
`;

const MapWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div<ContentProps>`
  width: 640px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: ${(props) => props.contentHeight}px;
`;

const RelatedLink = styled.a`
  color: #000;
  font-weight: bold;
  font-size: 20px;
  display: block;
  width: 320px;
  text-align: center;
  background-color: #fff;
  padding: 12px 14px 10px;
  border-radius: 30px;
  margin-top: 40px;
  text-decoration: none;
  ${mediaMobile`
    margin-top: 80px;
  `}
  &:hover {
    color: #fff;
    background-color: #666;
  }
`;

export const Home: React.FC<{}> = () => {
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    });
  }, []);
  console.log(windowSize.width, windowSize.height);

  const innerWidth = windowSize.width;
  const innerHeight = windowSize.height;
  //Math.floor(windowWidth * 0.5625);
  let playerWidth = 0;
  let playerHeight = 0;
  if (innerWidth < innerHeight) {
    playerWidth = innerHeight * 1.78;
    playerHeight = innerHeight;
  } else {
    playerWidth = innerWidth;
    playerHeight = Math.floor(innerWidth * 0.5625);
  }

  return (
    <Layout title="ホーム">
      <VideoWrap contentHeight={playerHeight}>
        <StyledIframe src="https://player.vimeo.com/video/460065783?background=1&quality=720p" width={playerWidth} height={playerHeight} allow="autoplay; fullscreen"></StyledIframe>
        <Container>
          <Content contentHeight={playerHeight}>
            <MainHeadings>Shigeki Takeguchi</MainHeadings>
            <RelatedLink href="https://www.youtube.com/channel/UCUlecQ9q3yVvV4kuw8ZC1IA?sub_confirmation=1" target="_blank">
              Subscribe on YouTube
            </RelatedLink>
          </Content>
        </Container>
      </VideoWrap>
      <MapWrap>
        <iframe title="ワンカット散歩動画の地図" src="https://www.google.com/maps/d/embed?mid=17t2Cg10_KpYVFjGOwaGWCwCGcrgCjUvi" width={playerWidth} height="720"></iframe>
      </MapWrap>
    </Layout>
  );
};
